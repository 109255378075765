<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Notificaciones - Configuración</h4>
            <div class="small text-muted">Configuración de los medios de notificaciones disponibles</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      
            <b-tabs v-model="tabIndex" card>
              
              <b-tab title="Email" :title-link-class="linkClass(0)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Email                      
                      <small>
                        <b-badge variant="success" v-if="crud.email.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>
                    </h5>                      
                  </div>
                  
                  <b-row>
                    <b-col md="6" sm="12">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="Servidor">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.email.host"                               
                                          placeholder="mail.example.com">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label="Puerto">
                            <b-form-input type="number"
                                          size="sm"
                                          v-model="crud.email.port"                               
                                          placeholder="25">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                        
                        <b-col md="3">
                          <b-form-group label="Cifrado">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.email.encryption"                               
                                          placeholder="tls">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 

                        <b-col md="6">
                          <b-form-group label="Usuario">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.email.username"                               
                                          placeholder="example@example.com">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Contraseña">
                            <b-input-group class="w-100">                      
                              <b-form-input :type="password.typePassword"
                                            size="sm"
                                            v-model="crud.email.password"                               
                                            placeholder="*******">
                              </b-form-input>
                              <b-input-group-append>
                                <b-button variant="secondary" size="sm" @click="changeTypePassword()">
                                  <i class="icon material-icons">{{password.iconPassword}}</i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col> 
                        
                        <b-col md="6">
                          <b-form-group label="Nombre Remitente">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.email.fromName"                               
                                          placeholder="example">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 

                        <b-col md="6">
                          <b-form-group label="Email Remitente">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.email.fromAddress"                               
                                          placeholder="example@example.com">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveEmail()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>  
                        <b-col md="12">
                          <hr>
                        </b-col> 
                        
                        <b-col md="12">
                          <b-form-group label="Email de Prueba">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.email.emailTest"                               
                                          placeholder="Ingrese un email para enviar la prueba">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                      </b-row>
                      <b-row>
                        <b-col md="12" class="mb-2">
                          <b-button variant="secondary" size="sm" @click="testEmail()">Probar Conexión</b-button>                          
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12">
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Lo recomendable es utilizar un servicio de email orientado a notificaciones para aplicaciones, como 
                          <a href="https://mailgun.com" target="_blank">Mailgun</a>,
                          <a href="https://mailtrap.io" target="_blank">Mailtrap</a> o                          
                          <a href="https://sendgrid.com/" target="_blank">Sendgrid</a>
                          <hr>
                          También se podría utilizar el servicio que le brinde su proveedor de correos corporativo.
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="SMS" :title-link-class="linkClass(1)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      SMS
                      <small>
                        <b-badge variant="success" v-if="crud.sms.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12">
                      <b-row>                                                
                        <b-col md="6">
                          <b-form-group label="Usuario">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.sms.username"                               
                                          placeholder="example@example.com">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          
                          <b-form-group label="Contraseña">
                            <b-input-group class="w-100">  
                              <b-form-input :type="password.typePassword"
                                            size="sm"
                                            v-model="crud.sms.password"                               
                                            placeholder="*******">
                              </b-form-input>

                              <b-input-group-append>
                                <b-button variant="secondary" size="sm" @click="changeTypePassword()">
                                  <i class="icon material-icons">{{password.iconPassword}}</i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col> 
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveSMS()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <hr>
                        </b-col> 
                        <b-col md="12">
                          <b-form-group label="Télefono de Prueba">
                            <b-form-input type="number"
                                          size="sm"
                                          v-model="crud.sms.nroTest"                               
                                          placeholder="Ingrese un nro sin el 0 ni el 15 (10 digitos total)">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                      </b-row>
                      <b-row>
                        <b-col md="12" class="mb-2">
                          <b-button variant="secondary" size="sm" @click="testSMS()">Probar Conexión</b-button>                          
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12">
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Las notificaciones vía SMS esta implementado a través de 
                          <a href="https://www.smsmasivos.com.ar" target="_blank">SMSMasivos by Skillmedia</a>                          
                          <br>
                          Cree una cuenta y configure los datos solicitados para que nuestro sistema comience a notificar.
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>
              
              <b-tab title="Whatsapp" :title-link-class="linkClass(2)">
                  <tab-whatsapp></tab-whatsapp>
              </b-tab>


              <b-tab title="Push" :title-link-class="linkClass(3)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      PUSH
                      <small>
                        <b-badge variant="success" v-if="crud.push.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12">
                      <b-row>  

                        <b-col md="12">
                          <b-form-group label="SERVER KEY">
                            <b-form-textarea                
                                v-model="crud.push.serverKey"
                                placeholder="xxxxxxxxxxx"
                                rows="2"
                                max-rows="6">
                            </b-form-textarea>                            
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group label="API KEY">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.push.apiKey"                               
                                          placeholder="xxxxxxxxxxx">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">                          
                          <b-form-group label="Project ID">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.push.projectId"                               
                                          placeholder="[nameProject]">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                                                                             
                        <b-col md="6">                          
                          <b-form-group label="Messaging Sender ID">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.push.messagingSenderId"                               
                                          placeholder="xxxxxxxxxxx">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                          
                        <b-col md="12">                          
                          <b-form-group label="Auth Domain">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.push.authDomain"                               
                                          placeholder="[nameProject].firebaseapp.com">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                     
                        <b-col md="12">                          
                          <b-form-group label="Storage Bucket">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.push.storageBucket"                               
                                          placeholder="[nameProject].appspot.com">
                            </b-form-input>
                          </b-form-group>
                        </b-col>                         
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="savePush()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <hr>
                        </b-col>                                                 
                      </b-row>                      
                      <b-row>
                        <b-col md="12" class="mb-2">
                          <b-button variant="secondary" size="sm" @click="testPush()">Probar Conexión</b-button>                          
                        </b-col>                
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12">
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>
                          Las notificaciones Push se implementa a través de 
                          <a href="https://console.firebase.google.com/" target="_blank">Firebase</a>                          
                          <br><br>
                          Desde una cuenta de Google: 
                          <ul>
                            <li>Cree un <b>nuevo proyecto</b>.</li>                            
                            <li>Dentro del proyecto, crea una <b>Firebase App Web</b>.</li>
                            <li>Ingrese a la <b>configuración</b> del proyecto.</li>                            
                            <li>En la pestaña <b>General --> Tus Apps</b>, puede obtener los datos de configuración para la <b>Firebase App Web</b>.</li>
                            <li>En la pestaña <b>Cloud Messaging</b>, puede obtener la clave del servidor.</li>
                          </ul>
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>              
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import TabWhatsapp from './settings/crudWhatsapp'

  export default {
    components: {
      TabWhatsapp,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.NOTIFICACIONES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: {
          email: {
            id: 1,
            host: '',
            port: 0,
            encryption: '',
            username: '',
            password: '',
            fromName: '',
            fromAddress: '',
            emailTest: '',
            active: false,
          },   
          sms: {
            id: 1,            
            username: '',
            password: '',
            nroTest: '',
            active: false,
          },      
          push: {
            id: 1,            
            serverKey: '',
            apiKey: '',
            authDomain: '',
            storageBucket: '',
            projectId: '',
            messagingSenderId: '',
            active: false,
          }        
        },  
        password: {
          typePassword: 'password',
          iconPassword: 'visibility',
        },
        tabIndex: 0     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.showEmail()
      this.showSMS()
      this.showPush()
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      }, 

      showEmail() {        
        var result = serviceAPI.showEmail(this.crud.email)

        result.then((response) => {
          var data = response.data
          
          this.crud.email.host = data.host_smtp
          this.crud.email.port = data.port_smtp
          this.crud.email.encryption = data.encryption          
          this.crud.email.username = data.username
          this.crud.email.password = data.password
          this.crud.email.fromName = data.from_name
          this.crud.email.fromAddress = data.from_address          
          this.crud.email.active = data.active          
        })
      },
      saveEmail() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de email?', {
          title: 'Guardar Email',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.saveEmail(this.crud.email);

            result.then((response) => {          
              loader.hide()          
              this.showEmail()
              this.$awn.success("Datos de email guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      testEmail() {

        var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
        if (!expreg.test(this.crud.email.emailTest)) {          
          this.$awn.alert("No se puede realizar el test sino se ingresa un email válido")          
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Probar configuración de email?', {
          title: 'Configuración de email',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.testEmail(this.crud.email)

            result.then((response) => {               
              loader.hide()
              this.showEmail()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      

      showSMS() {        
        var result = serviceAPI.showSMS(this.crud.sms)

        result.then((response) => {
          var data = response.data
                              
          this.crud.sms.username = data.username
          this.crud.sms.password = data.password
          this.crud.sms.active = data.active          
        })
      },   
      saveSMS() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de sms?', {
          title: 'Guardar SMS',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.saveSMS(this.crud.sms);

            result.then((response) => {          
              loader.hide()          
              this.showSMS()
              this.$awn.success("Datos de SMS guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },               
      testSMS() {        
        if(this.crud.sms.nroTest.length != 10) {
          this.$awn.alert("No se puede realizar el test sino se ingresa un nro de teléfono válido")
          return false
        }

        this.$bvModal.msgBoxConfirm('¿Probar configuración de SMS?', {
          title: 'Configuración de SMS',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();
            var result = serviceAPI.testSMS(this.crud.sms)

            result.then((response) => {               
              loader.hide()
              this.showSMS()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },     

      changeTypePassword() {
        if (this.password.typePassword === 'password') {
          this.password.typePassword = 'text'
          this.password.iconPassword = 'visibility_off'
        } else {
          this.password.typePassword = 'password'
          this.password.iconPassword  = 'visibility'
        }
      }, 
      
      showPush() {        
        var result = serviceAPI.showPush(this.crud.push)

        result.then((response) => {
          var data = response.data
          
          this.crud.push.serverKey = data.serverKey
          this.crud.push.apiKey = data.apiKey
          this.crud.push.authDomain = data.authDomain
          this.crud.push.storageBucket = data.storageBucket
          this.crud.push.projectId = data.projectId
          this.crud.push.messagingSenderId = data.messagingSenderId
          this.crud.push.active = data.active    
        })
      },
      savePush() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de Notificaciones Push?', {
          title: 'Guardar Notificaciones Push',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.savePush(this.crud.push);

            result.then((response) => {          
              loader.hide()          
              this.showPush()
              this.$awn.success("Datos de Notificaciones Push guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      testPush() {        
        this.$bvModal.msgBoxConfirm('¿Probar configuración de Notificaciones Push?', {
          title: 'Configuración de Notificaciones Push',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.testPush(this.crud.push)

            result.then((response) => {               
              loader.hide()
              this.showPush()
              this.$awn.success('Conexión en funcionamiento!!')
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
      },               
    } 
  }
</script>