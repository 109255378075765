<template>
  <b-card-text>
    <b-row class="mb-1">
      <b-col md="6" sm="12">
        <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Whatsapp">
          <i class="fa fa-plus"></i> Agregar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="6" sm="12">
        <b-table class="mb-0"
                responsive="sm"
                head-variant="dark"
                :hover="true"
                :small="true"
                :fixed="false"
                :items="table.items"
                :fields="table.fields"
                :filter="table.filter"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                v-if="table.items.length">

          <template v-slot:cell(id)="data">
            <b>{{ data.item.id }}</b>
          </template>

          <template v-slot:cell(active)="data">
            <div v-if="data.item.active">
              <b-badge variant="success">Activo</b-badge>
            </div>
            <div v-else>
              <b-badge variant="danger">Inactivo</b-badge>
            </div>
          </template>

          <template v-slot:cell(f_action)="data">
            <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
              <b-dropdown-item @click="testConnection(data.item)" v-if="data.item.api && data.item.token">
                <b-icon icon="plug"></b-icon> Probar Conexión
              </b-dropdown-item> 
              <b-dropdown-header>Acciones</b-dropdown-header>
              <b-dropdown-item @click="edit(data.item)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="remove(data.item)">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
      </b-col>
      <b-col md="6" sm="12">
        <b-card bg-variant="light" header="Implementación" text-variant="dark">
          <b-card-text>
            Las notificaciones vía Whatsapp esta implementado a través de 
            <a href="https://chat-api.com" target="_blank">Chat API</a>
            <br>
            Cree una cuenta y configure los datos solicitados para que nuestro sistema comience a notificar.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
                          
    <b-row>
      <b-col>
        <nav>
          <b-pagination class="pull-right mb-0"
                        size="sm"
                        pills=""
                        :total-rows="getRowCount(table.items)"
                        :per-page="table.perPage"
                        v-model="table.currentPage" />
        </nav>
      </b-col> 
    </b-row>            

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">

      <div slot="modal-header">
        {{this.modal.form.title}}
      </div>

      <b-row>
        <b-col md="12">
          <b-form-group label="Referencia">
            <b-form-input type="text"
                          size="sm"
                          v-model="crud.form.name"
                          required>
            </b-form-input>
          </b-form-group>
        </b-col>                    
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="API">
            <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.api"
                            required>
            </b-form-input>
          </b-form-group>
        </b-col>                    
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="Token">
            <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.token"
                            required>
            </b-form-input>
          </b-form-group>
        </b-col>                    
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="save()">Guardar</b-button>          
      </div>
    </b-modal>
  
    <!-- MODAL TEST -->
    <b-modal v-model="modal.formTest.active"
            header-bg-variant="dark"
            header-text-variant="white">

      <div slot="modal-header">
        {{this.modal.formTest.title}}
      </div>

      <b-row>
        <b-col md="12">
          <b-form-group label="Referencia">
            <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            disabled>
            </b-form-input>
          </b-form-group>
        </b-col>                    
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="API">
            <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.api"
                            disabled>
            </b-form-input>
          </b-form-group>
        </b-col>                    
      </b-row>

      <b-row>
        <b-col md="12">
          <b-form-group label="Token">
            <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.token"
                            disabled>
            </b-form-input>
          </b-form-group>
        </b-col>                    
      </b-row>

      <b-row> 
        <b-col md="12">
          <b-form-group label="Télefono de Prueba">
            <b-form-input type="number"
                          size="sm"
                          v-model="crud.form.nroTest"                               
                          placeholder="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
            </b-form-input>
          </b-form-group>
        </b-col> 
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.formTest.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="testWhatsapp()">Probar Conexión</b-button>          
      </div>
    </b-modal>

  </b-card-text>                        
</template>

<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Referencia', class:"align-middle"},
            {key: 'api', label: 'API', class:"align-middle"},
            {key: 'token', label: 'Token', class:"align-middle text-center"},            
            {key: 'active', label: 'Activo', class:"align-middle text-center"},
            {key: 'f_action', label:'', class:"align-middle"},
          ],                    
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            api: '',
            token: '',
            nroTest: '',
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formTest: {
            active: false,
            title: ''
          },
        },
      }
    },
    mounted () {
      this.show()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      show() {        
        var result = serviceAPI.obtenerWhatsapp()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });  
      },
      add() {
        this.crud.form.id = 0                
        this.crud.form.name = ''
        this.crud.form.api = ''
        this.crud.form.token = ''

        this.modal.form.title = "Nuevo Whatsapp"
        this.modal.form.active = true
      },
      edit(item) {                
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.api = item.api
        this.crud.form.token = item.token

        this.modal.form.title = "Editar Whatsapp"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.api = item.api
        this.crud.form.token = item.token

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Whatsapp',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarWhatsapp(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.show()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarWhatsapp(this.crud.form);
        } else {
          var result = serviceAPI.agregarWhatsapp(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.show()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      testConnection(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.api = item.api
        this.crud.form.token = item.token
        this.crud.form.nroTest = ''

        this.modal.formTest.title = "Probar Conexión"
        this.modal.formTest.active = true
      },
      testWhatsapp() {
        if(this.crud.form.nroTest.length != 13) {
          this.$awn.alert("No se puede realizar el test sino se ingresa un nro de teléfono válido")
          return false
        }
        this.$bvModal.msgBoxConfirm('¿Probar configuración de whatsapp?', {
          title: 'Configuración de whatsapp',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'PROBAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {   
            let loader = this.$loading.show();
            var result = serviceAPI.testWhatsapp(this.crud.form)

            result.then((response) => {
              this.modal.formTest.active = false
              loader.hide()
              this.show()   
              this.$awn.success('Conexión en funcionamiento!!')              
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error))
            });
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })                
      }, 
    } 
  }
</script>